<template>
    <b-modal
      :ref="'historico-planificaciones-download-modal'"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Descarga los documentos asociados a esta planificacion"
    >
      <b-row>
        <b-col>
          <b-button-group>
            <b-button variant="outline-primary" :href="'https://www.dropbox.com/s/e44gro6cpvqjeqj/R_14_16112022%20%281%29.xlsx?dl=1'" target="blank">
              <feather-icon icon="DownloadCloudIcon" class="mr-50" />
              <span class="align-middle">Plan Cosecha</span>
            </b-button>
            <b-button variant="outline-primary" :href="'https://www.dropbox.com/s/e44gro6cpvqjeqj/R_14_16112022%20%281%29.xlsx?dl=1'" target="blank">
              <feather-icon icon="DownloadCloudIcon" class="mr-50" />
              <span class="align-middle">Plan Productivo</span>
            </b-button>
            <b-button variant="outline-primary" :href="'https://www.dropbox.com/s/e44gro6cpvqjeqj/R_14_16112022%20%281%29.xlsx?dl=1'" target="blank">
              <feather-icon icon="DownloadCloudIcon" class="mr-50" />
              <span class="align-middle">Match Demanda</span>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-modal>
  </template>
    <script>   
  
  
  
  import
  {
    BRow,
    BCol,
  
  } from "bootstrap-vue";
  
  
  export default {
    data()
    {
      return {
  
        demanda: null,
  
  
      };
    },
    components: {
      BRow,
      BCol,
    },
  
    directives: {
    },
  
    mounted()
    {
  
    },
  
    methods: {
  
      show(planificacion)
      {
        this.$refs['historico-planificaciones-download-modal'].show()
  
  
  
      }
  
  
    },
  };
    </script>
    
    <style scoped>
  .carga-data {
    margin-top: 4%;
  }
  </style>
    
    